<template>
  <DefaultLayout>
    <FloatingBox v-if="alert" class="sm:w-128">
      <Alert :alert="alert" />
    </FloatingBox>

    <FloatingBox v-else class="sm:my-8 sm:w-196">
      <spinner v-if="loading" />
      <div v-if="!loading && application">
        <div class="flex justify-between items-center flex-col sm:flex-row mb-8">
          <h1 class="mb-4 sm:text-5xl">{{$t('apply.title')}}</h1>
          <img v-if="application.design" :src="designs[application.design]" class="inline ml-4 h-32">
          <img v-else src="../assets/card.png" class="inline ml-4 h-32">
        </div>

        <form-fieldset class="mb-4">
          <div class="grid grid-cols-1 sm:grid-cols-4 sm:gap-4">
            <div class="col-span-3">
              <form-input disabled :label="$t('apply.first_name')" :value="application.first_name" />
            </div>
            <div>
              <form-input :label="$t('apply.middle_name')" :validation="$v.application.middle_name" v-model="$v.application.middle_name.$model" />
            </div>
          </div>

          <form-input disabled :label="$t('apply.last_name')" :value="application.last_name" />

          <div class="grid grid-cols-1 sm:grid-cols-5 sm:gap-4">
            <div class="col-span-2">
              <form-input :label="$t('apply.nib')" :validation="$v.application.nib" v-model="$v.application.nib.$model" />
            </div>
            <div class="col-span-3">
              <form-input disabled :label="$t('apply.email')" :value="application.email" />
            </div>
          </div>
          
          <form-input disabled :label="$t('apply.phone')" :value="application.phone" />
        </form-fieldset>

        <form-fieldset class="mb-4" :legend="$t('apply.options')">
          <div class="grid grid-cols-1 sm:grid-cols-5 sm:gap-4">
            <div class="col-span-2">
              <form-input type="select" :label="$t('apply.design')" :validation="$v.application.design" v-model="$v.application.design.$model" :options="designOptions" />
            </div>
            <div class="col-span-3">
              <form-input disabled :label="$t('apply.location')" :validation="$v.application.location_id" :value="location ? location.name : null">
                <template #suffix>
                  <Button class="bg-brand border-brand input-suffix" @click="selectLocation">
                    <template v-if="location">{{$t('apply.change-location')}}</template>
                    <template v-else>{{$t('apply.select-location')}}</template>
                  </Button>
                </template>
              </form-input>
            </div>
          </div>

        </form-fieldset>

        <!-- <form-fieldset :legend="$t('apply.signature.title')">
          <form-input :validation="$v.application.signature">
            <template #input="{invalid}">
              <Signature v-model="$v.application.signature.$model" :invalid="invalid" />
            </template>
          </form-input>
        </form-fieldset> -->

        <form-fieldset>
          <form-input type="checkbox" :validation="$v.application.terms">
            <input type="checkbox" class="rounded mr-2 mb-1" :class="{'bg-yellow-500':$v.application.terms.$error}" v-model="$v.application.terms.$model">
            <span v-text="$t('apply.i-accept')"></span>
            <a href="https://islandluck.com" target="_blank" class="font-medium text-brand-light">
              <span v-text="$t('apply.terms-conditions')"></span>
            </a>
          </form-input>
        </form-fieldset>

        <div class="rounded-lg border border-yellow-500 text-yellow-500 p-2 mb-4" v-if="errors.length">
          <ul class="ml-6 list-disc">
            <li v-for="(error,i) in errors" :key="i" v-text="error" />
          </ul>
        </div>

        <form-fieldset>
          <Button :disabled="submitting" class="w-full bg-brand text-xl ml-0 m-1" @click="submit">{{$t('apply.submit')}}</Button>
        </form-fieldset>

      </div>
      <!-- <code class="block whitespace-pre-wrap">{{$v}}</code> -->
      <!-- <code class="block whitespace-pre-wrap">{{application}}</code> -->

    </FloatingBox>

  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/Default.vue'
import api from '../api';
import Spinner from '../components/Spinner.vue';
import FormInput from '../components/Forms/FormInput.vue';
import Button from '../components/Button.vue';
import FormFieldset from '../components/Forms/FormFieldset.vue';
// import Signature from '../components/Signature.vue';
import designOptions from '../assets/designs.json'
import Clickbox from 'clickbox-widget'
import { validationMixin } from 'vuelidate'
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import Alert from '../components/Alert.vue';
import FloatingBox from '../components/FloatingBox.vue';

const clickbox = new Clickbox(process.env.VUE_APP_CLICKBOX_TOKEN)

export default {
  name: 'Apply',
  mixins: [validationMixin],
  components: {
    DefaultLayout,
    Spinner,
    FormInput,
    Button,
    FormFieldset,
    // Signature,
    Alert,
    FloatingBox,
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      alert: null,
      loading: true,
      submitting: false,
      application: null,
      location: null,
      designOptions,
      designs: {},
      errors: [],
      form: {},
    }
  },
  validations: {
    application: {
      middle_name: {
        required,
      },
      nib: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(9),
        required,
      },
      design: {
        required,
      },
      location_id: {
        required,
      },
      terms: {
          terms: value => value === true
      }
      // signature: {
      //   required,
      // },
      // phone: {
      //   required,
      //   phoneNumber
      // }
    }
  },
  async created() {
    await this.fetch()
    for(let design of designOptions) {
      this.designs[design.value] = (await import(`../assets/${design.image}`)).default
    }
  },
  computed: {
    ready() {
      return this.application.signature
    },
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const {data: {data,meta}} = await api.get(`apply/${this.token}`)
        this.application = data
        this.location = meta.location
      } catch (error) {
        const {response: {status,data}} = error

        const displayStatus = [401,403,404].includes(status) ? status : 'unknown'

        this.alert = {
          type: displayStatus === 403 ? 'warning' : 'error',
          title: this.$t(`apply.error.server-${displayStatus}`),
          text: this.$t(`apply.error.server-${displayStatus}-description`,{status, message: data.message}),
        }

      } finally {
        this.loading = false
      }
    },
    async submit() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.errors = [this.$t('apply.error.client')]
      } else {
        this.errors = []
        this.submitting = true
        try {
          await api.post(`apply/${this.token}`,this.application)
          this.$router.push({name: 'confirmation'})
        } catch (error) {
          const {response: {data}} = error
          if(data && 'errors' in data) {
            this.errors = Object.values(data.errors).reduce((c,a)=> c.concat(a),[])
          } else {
            this.errors = [this.$t('apply.error.server-unknown')]
          }
        } finally {
          this.submitting = false
        }
      }
    },
    async selectLocation() {
      const location = await clickbox.selectLocation()
      if(location) {
        this.application.location_id = location.id
        this.$v.application.location_id.$touch()
        this.location = location
      }
    }
  },
  metaInfo() {
    return {
      title: this.$t('apply.title'),
    }
  } 
};
</script>
